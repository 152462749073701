import React from "react"
import { Link, graphql } from "gatsby"

import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/UI/Layout"
import Seo from "../components/UI/Seo"
import Tags from "../components/Content/Tags"

type BlogPostProps = {
  data: GatsbyTypes.BlogPostByIdQuery
}

const BlogPostTemplate = ({ data }: BlogPostProps ) => {
  const { previous, next, post } = data

  return (
    <Layout pageTitle="< News">
      <Seo title={post?.title} description={post?.excerpt} meta={[
        { name: `description`, content: post?.seo?.metaDesc || post?.excerpt! },
        { name: `keywords`, content: post?.seo?.focuskw || `` },
        { property: `og:title`, content: post?.seo?.opengraphTitle || post?.title! },
        { property: `og:description`, content: post?.seo?.opengraphDescription || post?.excerpt! },
        { property: `og:type`, content: `article` },
        { property: `og:author`, content: post?.seo?.opengraphAuthor || `` },
        { property: `og:image`, content: post?.seo?.opengraphImage?.sourceUrl || `` },
        { property: `og:image:alt`, content: post?.seo?.opengraphImage?.altText || `` },
        { property: `og:url`, content: post?.seo?.canonical || `` },
        { name: `twitter:card`, content: `summary` },
        { name: `twitter:title`, content: post?.seo?.twitterTitle || post?.title! },
        { name: `twitter:description`, content: post?.seo?.twitterDescription || post?.excerpt! },
        { name: `twitter:image`, content: post?.seo?.twitterImage?.sourceUrl || post?.excerpt! },
      ]} />
      <div className="mb-32">
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article">
          <header>
            <h1 itemProp="headline" className="font-bold text-lg mb-4 tracking-wide text-gray-700 not-italic">{parse(post?.title!)}</h1>
          </header>

          {!!post?.content! && (
            <section itemProp="articleBody">{parse(post?.content!)}</section>
          )}

          <p className="italic">Posted on {post?.date}</p>

          <Tags tags={post?.tags} />

          <hr />

        </article>
        <nav className="blog-post-nav mt-24">
          <ul className="flex flex-col">
            <li className="mb-4">
              {previous && (
                <Link to={previous?.uri!} rel="prev">
                  &larr; {parse(previous?.title!)}
                </Link>
              )}
            </li>
            <li className="flex justify-end">
              {next && (
                <Link to={next?.uri!} rel="next">
                  {parse(next?.title!)} &rarr;
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      tags {
        nodes {
          uri
          name
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        opengraphAuthor
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
        }
      }
    }
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
